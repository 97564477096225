import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import EdgeButton from '@/components/_buttons/EdgeButton';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export default function Custom404() {
  const router = useRouter();
  const { t } = useTranslation();

  const toHome = React.useCallback(() => {
    router.push('/');
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <p className="text-white text-3xl mb-8">{t('noContent')}</p>
      <EdgeButton className="h-[100px] bg-contain" onClick={toHome}>
        {t('backToHome')}
      </EdgeButton>
    </div>
  );
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], null, [
        'us',
        'jp',
        'kr',
      ])),
    },
  };
}
